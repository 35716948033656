body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidden {
  display: none;
}

.page-container{
  max-width: 1000px;
  margin: 0 auto;
  padding: 120px 20px;
  min-height: 100%;
}

.body-wrapper {
  min-height: calc(100vh - 245px);
}

.body-wrapper-child-sm {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 769px) {
  .body-wrapper-child-lg {
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 768px) {
  .body-wrapper-child-lg {
    padding: 120px 40px 0;
  }
}



.footer-logo{
  display:block;
  height:70px;
  margin: 0px auto 15px !important;
}

footer {
  bottom: 0;
  width: 100%;
  background-color:#38424A !important;
  background-image: none !important;
  border-radius: 0px !important;
  color: #fff;
}

footer > div {
  padding: 33px 10px;
}

.logo-brand{
  width:180px;
}

.navbar-inverse{
  font-size: 18px;
  background-color:#38424A !important;
  background-image: none !important;
  border-radius: 0px !important;
  color: #fff;
}

.padding-120{
  padding: 120px 0;
}

.white-link{
  color: #fff;
}

.small-text{
  font-size: 11.9px;
}

.no-margin {
  margin: 0px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.custom-tooltip {
    color: #aaa;
    margin: -4px 0 0 5px;
    position: absolute;
}

.addressList {
  position: absolute;
  z-index: 9;
}

.addressListItem {
  padding: 5px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-transform: capitalize;
}

.alert-toast {
  position: relative !important;
  width: fit-content !important;
  margin: 5px;
  left: auto !important;
  bottom: 0 !important;
  transform: none !important;
  z-index: 9 !important;
}

.card-header-title {
  float:left;
  font-size: 20px;
  text-transform: none;
}

.card-subheader-title {
  float:right;
  font-size: 12px;
  color: #38424a !important;
  text-transform: capitalize;
  vertical-align: middle;
  line-height: 28px;
}

.text-left{
  text-align: left;
}

.country-code-label{
  font-size: 16px;
  margin-top: 10px;
}

.tab-label {
  font-size: 13px;
  font-weight: 600;
}

label{
  text-transform: capitalize;
}

.address-state-link{
  color: #3399FF;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
  font-family: Poppins, "Open Sans", sans-serif;
  text-transform: capitalize;
  text-transform: initial !important;
  text-align: left;
}

.text-field-skeleton {
  margin-top: -8px !important;
}

p {
  text-transform: none !important;
}

a {
  cursor: pointer;
  color: #3399FF !important;
  text-decoration: none;
  text-transform: none !important;
}

svg{
  cursor: pointer;
}

.custom-tooltip {
  color: #38424A !important;
}

.info-link {
  color: #3399FF;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
}

.typography {
  margin: 12px 8px 0px 12px !important;
}

.input-field-label {
  font-size: 21px !important;
  white-space: nowrap;
}

.na-page-text {
  font-family: 'Source Sans Pro';
  color: #38424A !important;
}

/* *********************************MATERIAL UI ****************************** */
.MuiFormControl-root{
  width: 100%;
}

.MuiInputLabel-formControl {
  font-weight: 500 !important;
  color: #38424A !important;
  line-height: 24px !important;
  font-style: normal !important;
}  

.MuiTab-textColorPrimary.Mui-selected {
  background-color: #fff;
  margin: 5px;
}

[class*="PrivateTabIndicator-colorPrimary-"] {
  background-color: inherit !important;
}

.MuiTab-textColorPrimary.Mui-disabled{
  color: #444 !important;
}

.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
}

.MuiTab-root, .MuiTypography-body1 {
  text-transform: none !important;
}

.MuiAlert-message, .MuiFormHelperText-root{
  text-transform: initial !important;
}

/* Overrride the selected tab underline color. Requirement as per the designs for portal */
.MuiTabs-scroller > .jss13 {
  background-color: transparent !important;
}

.Mui-disabled {
  background: #B9B9B9 !important;
  color: #fff !important;
}

.MuiInputBase-input {
  font-family: Poppins, "Open Sans", sans-serif !important;
}

.MuiButton-label {
  text-transform: none !important;
}
